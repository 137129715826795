@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-full,
  .print-full * {
    flex-basis: 100%;
  }
}
